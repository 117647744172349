<template>
  <div v-if="pasteId">
    <img v-if="isModalVisible" class="logo" alt="Vue logo" src="@/assets/logo.png">
    <div v-if="!isModalVisible" class="uploadForm">
      <div class="form-control pasteText" id="paste">{{  paste.paste }}</div>
      <div id="sidebar">
        <h1>LockPaste</h1>
        <h2>Protect your pastes!</h2>
        <h3>{{ this.paste.title }}</h3>
        <h3>{{ this.paste.author }}</h3>
        <h3>{{ this.paste.notes }}</h3>
      </div>

    </div>

    <div v-if="isModalVisible" class="modal fade" id="passwordModal" tabindex="-1" role="dialog"
      aria-labelledby="passwordModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title" id="passwordModalLabel">Paste protected by password</h1>
          </div>
          <div class="modal-body">
            This paste is protected. Please enter the password to unlock it.
          </div>

          <div :class="wrongPassword ? 'form__group__wrong' : 'form__group'">
            <input type="password" class="form__field" id="password" placeholder="Password" aria-label="Password"
              aria-describedby="basic-addon1" v-model="paste.password"/>
            <label for="name" class="form__label">Password</label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="getPaste">Unlock</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="uploadForm">
    <textarea type="content" class="form-control" id="paste" placeholder="Paste your team here" aria-label="Paste your team here"
      aria-describedby="basic-addon1" v-model="paste.content"></textarea>
    <div id="sidebar">
      <h1>LockPaste</h1>
      <h2>Protect your pastes!</h2>
      <div class="form__group field">
        <input class="form__field" id="title" placeholder="Title" aria-label="Title"
          aria-describedby="basic-addon1" v-model="paste.title"/>
        <label for="name" class="form__label">Title</label>
      </div>

      <div class="form__group field">
        <input class="form__field" id="author" placeholder="Author" aria-label="Author"
          aria-describedby="basic-addon1" v-model="paste.author"/>
        <label for="name" class="form__label">Author</label>
      </div>

      <div class="form__group field">
        <input class="form__field" id="notes" placeholder="Notes" aria-label="Notes"
          aria-describedby="basic-addon1" v-model="paste.notes"/>
        <label for="name" class="form__label">Notes</label>
      </div>

      <div class="form__group field">
        <input type="password" class="form__field" id="password" placeholder="Password" aria-label="Password"
          aria-describedby="basic-addon1" v-model="paste.password"/>
        <label for="name" class="form__label">Password</label>
      </div>
    
      
      <button type="button" class="btn btn-primary" @click="savePaste">Upload paste!</button>
    </div>

  </div>
</template>

<script>
import PastesDataService from "../services/PastesDataService";

export default {
  name: 'LockPaste',
  data() {
    return {
      paste: {
        title: '',
        content: ''
      },
      isModalVisible: false,
      wrongPassword: false,
      pastePassword: '',
      pasteId: ''
    };
  },
  created() {
    console.log("mounted!");

    this.$watch('$route.params.id', function (newVal) {
      console.log("route changed!");
      this.pasteId = newVal;
    });

    this.showModal();
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    getPaste() {
      PastesDataService.get(this.pasteId, this.paste.password)
        .then(response => {
          this.paste = response.data;
          console.log(response.data);
          this.closeModal();
        })
        .catch(e => {
          console.log(e);
          this.wrongPassword = true;
        });
      console.log(this.paste);
    },
    savePaste() {
      var data = {
        title: this.paste.title,
        paste: this.paste.content,
        author: this.paste.author,
        notes: this.paste.notes,
        password: this.paste.password
      };
      console.log(process.env.VUE_APP_API_URL);
      PastesDataService.create(data)
        .then(response => {
          this.pasteId = response.data.id;
          this.pastePassword = response.data.password;
          history.pushState(
            {},
            null,
            this.$route.path + this.pasteId
          )
          console.log(response.data);
          this.getPaste();
        })
        .catch(e => {
          console.log(e);
        });
      console.log(this.paste);
    }
  },

  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  height: 200px;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

/* The Modal (background) */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: rgba(30, 30, 30);
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}

.uploadForm {
  display: flex;
  flex-flow: row wrap;
}

.form-control.pasteText {
  flex: 1;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  white-space: pre-wrap;
}

textarea {
	flex: 1 1 auto;
	width: 600px;
	height: 100vh;
	margin: 0;
	border: 0;
	padding: 12px;
	box-sizing: border-box;
	background-color: black;
	color: white;
	font: 12px "M+ 1m", monospace;
}

div#sidebar {
	/*flex: 0 1 auto;*/
	width: 300px;
	padding: 0 12px;
	box-sizing: border-box;
	font: 12px "M+ 1c", sans-serif;
  background-color: rgb(100, 100, 100);
}

input {
	width: 100%;
	margin: 5px 0px;
	border: 0;
	padding: 6px;
  box-sizing: border-box;
	background-color: inherit;
	font: inherit;
	font-size: larger;
	text-align: center;
  color: white;
}

textarea:focus, input:focus{
    outline: none;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  font-size: 1.3rem;
  color: white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: gray;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}

.form__group__wrong {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;

  animation: horizontal-shaking 0.5s;
}

.form__group__wrong .form__field {
  color: red;
  border-bottom: 2px solid red;
}

.form__group__wrong .form__label {
  color: red;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: gray;
    font-weight:700;    
  }
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, gray,darkgray);
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

.btn {
  width: 100%;
  background-color: #c0c0c0;
  color: black;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
}

</style>
