import http from "../http-common";

class PastesDataService {
  get(id, password) {
    return http.post(`/api/pastes/${id}?password=${password}`);
  }

  create(data) {
    return http.post("/api/pastes", data);
  }

}

export default new PastesDataService();
