import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: "/:id",
        name: "paste-details",
        props: true,
        component: () => import("./components/LockPaste")
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
