<template>
  <LockPaste msg="Welcome to Your Vue.js App"/>
</template>

<script>
import LockPaste from './components/LockPaste.vue'

export default {
  name: 'App',
  components: {
    LockPaste
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: lightgrey;
}
body {
  background-color: black !important;
  margin: 0;
}
</style>
